import dayjs from "dayjs";
import { IPayment, IPaymentsResponse } from "libs/models/CustomerProducts";

export const mockPaymentOne: IPayment = {
  sequenceNumber: "1",
  customerNumber: "12345",
  totalAmount: 586.22,
  balance: -20394.66,
  insuranceFee: 0,
  adminFee: 25,
  interestRate: 0.1,
  interestAmount: 271.0,
  status: "pending",
  remainderAmount: 15000,
  invoiceNumber: "34815834",
  invoiceDate: dayjs("2023-10-10").toString(),
  periodStartDate: dayjs("2023-10-10").toString(),
  dueDate: dayjs("2023-10-10").toString(),
  valueDate: dayjs("2023-10-10").toString(),
  bookDate: dayjs("2023-10-10").toString(),
  paymentPeriod: dayjs("2023-10-10").toString(),
  paymentReference: {
    bankAccount: "12345678",
    businessIdentificationCode: "BIC",
    invoiceReference: "918273674",
    receiverName: "Karhu Koskenkorva",
    virtualBarcode: "FI42 3301 0001 1665 03",
  },
  installmentAmount: 247,
  isPending: true,
  promiseToPayDate: dayjs("2023-10-10").toString(),
};

export const mockPaymentTwo: IPayment = {
  sequenceNumber: "2",
  customerNumber: "12345",
  totalAmount: 10000,
  balance: 9000,
  insuranceFee: 0,
  adminFee: 25,
  interestRate: 0.1,
  interestAmount: 500,
  status: "pending",
  remainderAmount: 10000,
  invoiceNumber: "1234567890987",
  invoiceDate: dayjs("2023-10-01").toString(),
  periodStartDate: dayjs("2023-10-01").toString(),
  dueDate: dayjs("2023-10-01").toString(),
  valueDate: dayjs("2023-10-01").toString(),
  bookDate: dayjs("2023-10-01").toString(),
  paymentPeriod: dayjs("2023-10-01").toString(),
  paymentReference: {
    bankAccount: "12345678",
    businessIdentificationCode: "BIC",
    invoiceReference: "918273674",
    receiverName: "Karhu Koskenkorva",
    virtualBarcode: "FI42 3301 0001 1665 03",
  },
  installmentAmount: 0,
  isPending: false,
};

export const mockPaymentThree: IPayment = {
  sequenceNumber: "3",
  customerNumber: "12345",
  totalAmount: 5000,
  balance: 8000,
  insuranceFee: 0,
  adminFee: 25,
  interestRate: 0.1,
  interestAmount: 500,
  status: "pending",
  remainderAmount: 5000,
  invoiceNumber: "1234567891234",
  invoiceDate: dayjs("2023-09-15").toString(),
  periodStartDate: dayjs("2023-09-15").toString(),
  dueDate: dayjs("2023-09-15").toString(),
  valueDate: dayjs("2023-09-15").toString(),
  bookDate: dayjs("2023-09-15").toString(),
  paymentPeriod: dayjs("2023-09-15").toString(),
  paymentReference: {
    bankAccount: "12345678",
    businessIdentificationCode: "BIC",
    invoiceReference: "918273674",
    receiverName: "Karhu Koskenkorva",
    virtualBarcode: "FI42 3301 0001 1665 03",
  },
  installmentAmount: 0,
  isPending: false,
};

export const mockPaymentFour: IPayment = {
  sequenceNumber: "4",
  customerNumber: "12345",
  totalAmount: 5000,
  balance: 8000,
  insuranceFee: 0,
  adminFee: 25,
  interestRate: 0.1,
  interestAmount: 500,
  status: "pending",
  remainderAmount: 5000,
  invoiceNumber: "123456789321",
  invoiceDate: dayjs("2023-07-20").toString(),
  periodStartDate: dayjs("2023-07-20").toString(),
  dueDate: dayjs("2023-07-20").toString(),
  valueDate: dayjs("2023-07-20").toString(),
  bookDate: dayjs("2023-07-20").toString(),
  paymentPeriod: dayjs("2023-07-20").toString(),
  paymentReference: {
    bankAccount: "12345678",
    businessIdentificationCode: "BIC",
    invoiceReference: "918273674",
    receiverName: "Karhu Koskenkorva",
    virtualBarcode: "FI42 3301 0001 1665 03",
  },
  installmentAmount: 0,
  isPending: false,
};

export const mockPaymentFive: IPayment = {
  sequenceNumber: "5",
  customerNumber: "12345",
  totalAmount: 5000,
  balance: 8000,
  insuranceFee: 0,
  adminFee: 25,
  interestRate: 0.1,
  interestAmount: 500,
  status: "pending",
  remainderAmount: 5000,
  invoiceNumber: "1234567892",
  invoiceDate: dayjs("2023-05-01").toString(),
  periodStartDate: dayjs("2023-05-01").toString(),
  dueDate: dayjs("2023-05-01").toString(),
  valueDate: dayjs("2023-05-01").toString(),
  bookDate: dayjs("2023-05-01").toString(),
  paymentPeriod: dayjs("2023-05-01").toString(),
  paymentReference: {
    bankAccount: "12345678",
    businessIdentificationCode: "BIC",
    invoiceReference: "918273674",
    receiverName: "Karhu Koskenkorva",
    virtualBarcode: "FI42 3301 0001 1665 03",
  },
  installmentAmount: 0,
  isPending: false,
};

export const mockPaymentSix: IPayment = {
  sequenceNumber: "6",
  customerNumber: "12345",
  totalAmount: 5000,
  balance: 8000,
  insuranceFee: 0,
  adminFee: 25,
  interestRate: 0.1,
  interestAmount: 500,
  status: "pending",
  remainderAmount: 5000,
  invoiceNumber: "123456789",
  invoiceDate: dayjs("2023-04-01").toString(),
  periodStartDate: dayjs("2023-04-01").toString(),
  dueDate: dayjs("2023-04-01").toString(),
  valueDate: dayjs("2023-04-01").toString(),
  bookDate: dayjs("2023-04-01").toString(),
  paymentPeriod: dayjs("2023-04-01").toString(),
  paymentReference: {
    bankAccount: "12345678",
    businessIdentificationCode: "BIC",
    invoiceReference: "918273674",
    receiverName: "Karhu Koskenkorva",
    virtualBarcode: "FI42 3301 0001 1665 03",
  },
  installmentAmount: 0,
  isPending: false,
};

export const mockPaymentSeven: IPayment = {
  sequenceNumber: "7",
  customerNumber: "12345",
  totalAmount: 5000,
  balance: 8000,
  insuranceFee: 0,
  adminFee: 25,
  interestRate: 0.1,
  interestAmount: 500,
  status: "pending",
  remainderAmount: 5000,
  invoiceNumber: "1234567891",
  invoiceDate: dayjs("2023-03-01").toString(),
  periodStartDate: dayjs("2023-03-01").toString(),
  dueDate: dayjs("2023-03-01").toString(),
  valueDate: dayjs("2023-03-01").toString(),
  bookDate: dayjs("2023-03-01").toString(),
  paymentPeriod: dayjs("2023-03-01").toString(),
  paymentReference: {
    bankAccount: "12345678",
    businessIdentificationCode: "BIC",
    invoiceReference: "918273674",
    receiverName: "Karhu Koskenkorva",
    virtualBarcode: "FI42 3301 0001 1665 03",
  },
  installmentAmount: 0,
  isPending: false,
};

export const mockPaymentPlan: IPaymentsResponse = {
  payments: [
    mockPaymentOne,
    mockPaymentTwo,
    mockPaymentThree,
    mockPaymentFour,
    mockPaymentFive,
    mockPaymentSix,
    mockPaymentSeven,
  ],
};

export const mockOverduePayments = {
  payments: [mockPaymentOne, mockPaymentTwo, mockPaymentThree],
};

export const mockDuePayment = {
  payments: [mockPaymentOne],
};

export const mockPaidPayments = {
  payments: [mockPaymentOne, mockPaymentTwo, mockPaymentThree, mockPaymentFour],
};
