import React from "react";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { HtmlContent } from "~views/shared";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { NotFoundPage } from "./NotFound/NotFound";

export const StandardPage = observer(() => {
  const { contentStore } = useStores();

  const { currentPage } = contentStore;

  if (!currentPage) return <NotFoundPage />;

  const { pageTitle, pageText, pageBottomText } = currentPage;

  return (
    <>
      {(pageTitle || pageText) && (
        <section>
          {pageTitle && <h1>{pageTitle}</h1>}
          {pageText && <HtmlContent html={pageText} />}
        </section>
      )}
      <ContentArea />
      {pageBottomText && (
        <section>
          <HtmlContent html={pageBottomText} />
        </section>
      )}
    </>
  );
});
