import * as React from "react";
import { IPowerOfAttorneyPage } from "netbank-shared/src/libs/models/Content/Page";
import { observer } from "mobx-react";
import { tx } from "netbank-shared/src/libs/i18n";
import { IPowerOfAttorneyTerms } from "netbank-shared/src/libs/models/PowerOfAttorney";
import { ListItem } from "netbank-shared/src/libs/models/Content/ListItem";
import { useNavigate } from "react-router-dom";
import {
  getInsuranceCompanyDisplayValue,
  getInvalidCountries,
  parseBool,
  whitespaceToCamelCase,
} from "netbank-shared/src/libs/utils";
import { CheckboxFormField, DropdownFormField, FileAttachmentFormField, FormWrapper } from "~views/shared/Forms";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IPowerOfAttorneyForm, generatePowerOfAttorneySchema } from "netbank-shared/src/libs/forms/PowerOfAttorneyForm";
import { DatePickerFormField } from "~views/shared/Forms/DatePickerFormField";
import { RadioFormField } from "~views/shared/Forms/RadioFormField";
import { MultiSelectFormField } from "~views/shared/Forms/MultiSelectFormField";

interface IPowerOfAttorneyFormProps {
  pageData: IPowerOfAttorneyPage;
  terms: IPowerOfAttorneyTerms;
  goToAccountUrl: string;
  onSubmit: (formData: IPowerOfAttorneyForm) => Promise<void>;
}

export const PowerOfAttorneyForm = observer(
  ({ pageData, terms, goToAccountUrl, onSubmit }: IPowerOfAttorneyFormProps) => {
    const {
      buttonLabel,
      feeInformation,
      fileUploaderText,
      travelDateInput,
      insuranceCompanyInput,
      travellingToInvalidCountryInput,
      acceptTermsInput,
      countriesInput,
      insuranceDocumentInput,
    } = pageData;

    const defaultValues = {
      travelDate: undefined,
      insuranceCompany: tx("loan.powerOfAttorney.insuranceCompany"),
      travellingToInvalidCountry: undefined,
      acceptTermsAndExpense: false,
      countries: [],
      attachments: [],
    };

    const methods = useForm<IPowerOfAttorneyForm>({
      resolver: yupResolver(
        generatePowerOfAttorneySchema({
          terms,
          page: pageData,
        }),
      ),
      defaultValues,
    });

    const formValues = methods.watch();
    const { isSubmitted } = methods.formState;

    const insuranceCompanies: ListItem[] | undefined = terms?.insuranceCompanyTerms?.map((term, index) => {
      return {
        index,
        value: term.insuranceCompany || "",
        label: term.insuranceCompany ? getInsuranceCompanyDisplayValue(term.insuranceCompany) : "",
      };
    });

    const invalidCountries = getInvalidCountries(terms, formValues.insuranceCompany);

    const invalidCountriesString = invalidCountries
      ?.map((country) => tx(`countries.${whitespaceToCamelCase(country)}`))
      .join(", ");

    const countriesList = terms.insuranceCompanyTerms
      ?.find((term) => term.insuranceCompany === formValues.insuranceCompany)
      ?.invalidCountries?.map((country) => {
        return {
          value: country,
          label: tx(`countries.${whitespaceToCamelCase(country)}`),
        };
      });

    const disabledDates = [{ before: new Date(), after: new Date(new Date().setMonth(new Date().getMonth() + 3)) }];

    const navigate = useNavigate();

    const goBackToAccount = () => {
      navigate(goToAccountUrl);
    };

    const showTravellingToInvalidCountryField =
      formValues.insuranceCompany && invalidCountries && invalidCountries.length > 0;

    const showCountrySelect = showTravellingToInvalidCountryField && !!formValues.travellingToInvalidCountry;

    const showInsuranceUploader = showTravellingToInvalidCountryField && !!formValues.travellingToInvalidCountry;

    const onSubmitForm: SubmitHandler<IPowerOfAttorneyForm> = async (formData) => onSubmit(formData);

    const onCancel = () => {
      goBackToAccount();
    };

    return (
      <FormWrapper
        formMethods={methods}
        onSubmit={onSubmitForm}
        submitLabel={buttonLabel || tx("misc.send")}
        cancelAction={onCancel}
        cancelLabel={tx("selfServiceForm.goBack")}
      >
        <DatePickerFormField
          label={travelDateInput?.label || tx("date.newDueDay")}
          name="travelDate"
          infoPopover={{ popoverTitle: travelDateInput?.infoTitle, content: travelDateInput?.infoText }}
          disabledDays={disabledDates}
        />
        <DropdownFormField
          name="insuranceCompany"
          displayValue={getInsuranceCompanyDisplayValue(formValues.insuranceCompany)}
          label={insuranceCompanyInput?.label || tx("loan.powerOfAttorney.insuranceCompany")}
          list={insuranceCompanies}
          infoPopover={{ popoverTitle: insuranceCompanyInput?.infoTitle, content: insuranceCompanyInput?.infoText }}
          valueResetList={["travellingToInvalidCountry", "countries", "attachments"]}
          triggerValidation={isSubmitted}
        />
        {showTravellingToInvalidCountryField && (
          <>
            <RadioFormField
              name="travellingToInvalidCountry"
              label={travellingToInvalidCountryInput?.label || tx("loan.powerOfAttorney.countrySpecificationLabel")}
              topText={`<p>${invalidCountriesString}</p>`}
              infoPopover={{
                popoverTitle: travellingToInvalidCountryInput?.infoTitle,
                content: travellingToInvalidCountryInput?.infoText,
              }}
              list={[
                {
                  label: tx("misc.yes"),
                  value: "true",
                  index: 0,
                },
                {
                  label: tx("misc.no"),
                  value: "false",
                  index: 1,
                },
              ]}
              valueResetList={["countries", "attachments"]}
              triggerValidation={isSubmitted}
              transform={{
                input: (value) => value?.toString(),
                output: (value) => parseBool(value),
              }}
            />
            {showCountrySelect && (
              <MultiSelectFormField
                name="countries"
                label={countriesInput?.label || tx("loan.powerOfAttorney.countrySpecification")}
                placeholder={tx("misc.chooseCitizenshipPlaceholder")}
                options={countriesList}
                infoPopover={{ popoverTitle: countriesInput?.infoTitle, content: countriesInput?.infoText }}
              />
            )}
          </>
        )}
        {showInsuranceUploader && (
          <>
            <p>{fileUploaderText}</p>
            <FileAttachmentFormField
              fileInputId="PowerOfAttorney"
              name="attachments"
              label={insuranceDocumentInput?.label || tx("loan.powerOfAttorney.insuranceDocument")}
              infoPopover={{
                popoverTitle: insuranceDocumentInput?.infoTitle,
                content: insuranceDocumentInput?.infoText,
              }}
            />
          </>
        )}
        {feeInformation && <p>{feeInformation}</p>}
        <CheckboxFormField
          name="acceptTermsAndExpense"
          label={acceptTermsInput?.label || tx("loan.powerOfAttorney.acceptTerms")}
        />
      </FormWrapper>
    );
  },
);
